/* colors */
/*@blue:#415f8c;*/
/* PADDING */
/* TOOLBAR */
/*Toolbar colors*/
/*Toolbar button colors*/
/*Toolbar form elements*/
/*Toolbar dropdown menu*/
.altai-theme-admin .file-grid {
  display: flex;
  flex-wrap: wrap;
  margin-left: 20px;
}
.altai-theme-admin .file-grid .file-grid-item {
  border: 2px solid rgba(255, 255, 255, 0);
  width: 220px;
  margin-right: 10px;
  height: 165px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}
.altai-theme-admin .file-grid .file-grid-item img {
  max-height: 165px;
  max-width: 220px;
}
.altai-theme-admin .file-grid .file-grid-item:hover {
  background-color: #f7f7f7;
}
.altai-theme-admin .file-grid .file-grid-item .check-grid {
  display: none;
}
.altai-theme-admin .file-grid .file-grid-item.checked {
  border: 2px solid #0068ff;
}
.altai-theme-admin .file-grid .file-grid-item.checked .check-grid {
  display: table-cell;
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #0068ff;
  color: white;
  text-align: center;
  vertical-align: middle;
}
.altai-theme-admin .file-list {
  cursor: pointer;
}
.altai-theme-admin .file-list .check-list {
  visibility: hidden;
}
.altai-theme-admin .checked .check-list {
  visibility: visible;
}
